.workgroup-container {
  height: calc(100% - 76px);
  overflow-y: auto;
  &::-webkit-scrollbar{
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb{
    background-color: #e0e0e0;
    border-radius: 2px;
  }
  .workgroup-header {
    position: sticky;
    top: 0;
    background-color: #f7f9fc;
    color: #868fa0;
    padding: 8px 16px;
    .column-label {
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      svg {
        color: #464f60;
        font-size: 18px;
        margin-left: 4px;
        margin-right: 4px;
      }
    }
    .MuiTypography-root {
      font-size: 12px;
      font-weight: 500;
    }
  }
  .workgroup-list-container {
    padding: 0 24px;
    .workgroup-list {
      border-radius: 16px;
      padding: 8px 16px;
      color: #111827;
      white-space: nowrap;
      border: 1px solid #e0e0e0;
      position: relative;
      margin-top: 26px;
      .btn-wrapper{
        display: none;
      }
      &.fold{
        height: 54px;
        overflow-y: hidden;
        display: flex;
        align-items: center;
        .btn-wrapper{
          display: block;
        }
        .title{
          position: unset;
          width: 100%;
          justify-content: space-between;
          p{
            font-size: 16px !important;
          }
        }
        .task_management{
          display: none;
        }
      }
      &.active{
        .btn-wrapper{
          display: block;
        } 
      }
      .title{
        position: absolute;
        top: -18px;
        padding: 6px 12px;
        background-color: #fff;
        display: flex;
        min-height: 26px;
        gap: 15px;
        p{
          width: fit-content !important;
          font-weight: 600 !important;
          cursor: pointer;
          display: flex;
            align-items: center;
        }
        button{
          width: fit-content !important;
          margin-left: 0 !important;
        }
      }
      .MuiTypography-root {
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 14px;
        @media only screen and (max-width: 1023px) {
          font-size: 13px;
        }
      }
      .icon-btn {
        width: 2%;
      }
      .table-header-wrapper{
        justify-content: flex-end;
        padding-right: 0;
        padding-top: 8px;
      }
      .editable-table-wrapper{
        .MuiTableContainer-root{
          overflow-y: auto;
          max-height: 430px;
          .MuiTableBody-root{
            .MuiTableCell-root:first-child{
              padding-left: 0;
            }
          }
        } 
      }
    }
  }
}
