.table-wrapper {
  .MuiTableContainer-root {
    max-width: 100%;
    .custom-checkbox{
      label{
        margin-left: -4px;
        @media only screen and (max-width: 1023px) {
          margin-right: 8px;
        }
      }
    }
    .MuiTableHead-root {
      background-color: #f7f9fc;
      .MuiTableCell-root{
        padding-top: 0;
        padding-bottom: 0;
        border-top: 1px solid #E9EDF5;
        background-color: #f7f9fc;
      }
    }
    .MuiTableRow-root.Mui-selected {
      background-color: #4285f440;
    }
    .MuiTableRow-root.MuiTableRow-hover:hover {
      background-color: #8642930d;
    }
    .MuiTableCell-root {
      padding: 8px;
      font-size: 14px;
      white-space: nowrap;
      color: #111827;
      @media only screen and (max-width: 1023px) {
        font-size: 13px;
      }
      .MuiAvatar-root {
        height: 32px;
        width: 32px;
        border: 1px solid #E9EDF5;
        @media only screen and (max-width: 1023px) {
          height: 26px;
          width: 26px;
        }
      }
      &.MuiTableCell-paddingCheckbox{
        width: 42px;
        padding-right: 0;
        @media only screen and (max-width: 1023px) {
          width: 34px;
        }
        svg{
          width: 22px;
          height: 22px;
          color: #464f6052;
          border-radius: 4px;
          path{
            box-shadow: 0px 1px 1px #0000001A;
          }
        }
      }
    }
  }
}

.table-header-wrapper {
  display: flex;
  justify-content: space-between;
  height: 56px;
  align-items: center;
  padding: 8px 24px;
  padding-top: 16px;
  background-color: #ffffff;
  border-radius: 10px;
  @media only screen and (max-width: 1023px) {
    height: 56px;
    padding: 8px 18px;
  }
  .custom-tab{
    p{
      margin: 0;
    }
  }
  .headerButtons {
    display: flex;
    gap: 12px;
    @media only screen and (max-width: 1279px) {
      gap: 7px;
    }
  }
}

.main-wrapper{
  .page-table{
    &.toolbar-active{
      .table-wrapper{
        height: calc(100% - 188px);
      }  
    }
    .table-wrapper{
      height: calc(100% - 132px);
      .MuiTableContainer-root{
        overflow: auto;
        height: 100%;
        scrollbar-width: auto transparent;
        &::-webkit-scrollbar{
          width: 4px;
          height: 4px;
        }
        &::-webkit-scrollbar-thumb{
          border-radius: 4px;
          background-color: #ccc;
        }
      }
    }
  }
}

.table-toolbar {
    .MuiToolbar-root {
        display: flex;
        justify-content: space-between;
        min-height: 38px!important;
        padding: 8px 24px;
        border-bottom: 1px solid #D5DBE5;
        border-top: 1px solid #D5DBE5;
        @media only screen and (max-width: 1023px) {
          padding: 8px 16px;
        }
    }
}

.editable-table-wrapper {
  .table-header-wrapper {
    height: auto;
    align-items: flex-start;
    @media only screen and (max-width: 1279px) {
      padding: 8px 16px;
    }
  }
  .MuiTableContainer-root {
    .MuiTableHead-root {
      .MuiTableRow-root{
        &:nth-child(2){
          .MuiTableCell-root{
            border-top: 0 !important;
          }
        }
      }
      .MuiTableCell-head {
        position: relative;
        padding: 4px 8px;
        background-color: #f7f9fc;
        &:first-child{
          padding-left: 16px;
        }
        &:last-child{
          padding-right: 16px;
        }
        span{
          width: calc(100% - 20px);
          display: flex;
          .MuiTableSortLabel-root{
            width: 100%;
            p{
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100% !important;
            }
            .MuiSvgIcon-root{
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          .more-icon{
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .text-input-wrapper {
          gap: 0px;
        }
      }
    }
    .MuiTableBody-root {
      .MuiTableCell-root {
        padding: 8px;
        &:first-child{
          padding-left: 16px;
        }
        &:last-child{
          padding-right: 16px;
        }
        .select-dropdown {
          .MuiFormControl-root {
            margin: 0
          }
        }
        .text-input-wrapper {
          gap: 0px;
          
        }
        .datepicker-input {
          .MuiTypography-root {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }
}

.custom-table-skeleton {
  .MuiTableCell-root {
    padding: 16px !important;
  }
}

.archived-btn {
  svg {
    height: 20px;
    fill: #864293
  }
}

.play-btn {
  svg {
    fill: #864293
  }
}
.column-menu{
  .MuiPaper-root{
    min-width: 120px !important;
  }
  .MuiMenuItem-root{
    font-size: 14px;
  }
}
.datepicker-div{
  .datepicker-input{
    width: 100%;
    .MuiFormControl-root{
      width: 100%;
    }
  }
}