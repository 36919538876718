.calculation-step-wrapper{
    .form-title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .text-input-wrapper{
            gap: 0;
        }
        .flex-container{
            gap: 24px;
            .MuiTextField-root{
                width: 280px;
                input{
                    padding-left: 0;
                }
            }
        }
    }
    .group-card-wrapper{
        padding: 8px 24px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        .group_card{
            padding: 16px;
            border: 1px solid #E9EDF5;
            border-radius: 10px;
            padding-left: 10px;
            transition: height 0.3s ease-in-out;
            &.error{
                border-color: rgba(255, 0, 0, 0.25);
                box-shadow: 0px 0px 12px rgba(255, 0, 0, 0.15);
            }
            &.fold{
                height: 32px;
                overflow: hidden;
            }
            > .flex-container{
                justify-content: space-between;
                .group-head-items{
                    display: flex;
                    gap: 16px;
                    align-items: flex-end;
                    > .text-input-wrapper{
                        gap: 0;
                        width: 320px;
                    }
                    .group-type-field{
                        width: 20%;
                    }
                    .MuiTypography-headerh4{
                        margin-bottom: 7px;
                        color: #A1A9B8;
                    }
                }
            }
            .summary{
                margin-top: 20px;
                padding-left: 8px;
                .head{
                    display: flex;
                    p{
                        text-transform: uppercase;
                        font-weight: 600 !important;
                        &:first-child{
                            min-width: 220px;
                            margin-right: 20px;
                        }
                    }
                }
                .role-row{
                    display: flex;
                    align-items: center;
                    padding: 10px 16px;
                    min-height: 32px;
                    border: 1px solid #dddddd;
                    border-radius: 12px;
                    margin-top: 12px;
                    gap: 16px;
                    p{
                        min-width: 204px;
                    }
                    form{
                        display: flex;
                        gap: 12px;
                        >div{
                            display: flex;
                            align-items: center;
                            gap: 2px;
                        }
                        label{
                            font-size: 14px;
                            color: #687182;
                            font-weight: 500;
                        }
                    }
                    .text-input-wrapper{
                        gap: 0;
                    }
                }
            }
            .add-row-btn{
                position: absolute;
                top: -44px;
                left: 50%;
                transform: translateX(-50%);
                svg{
                    width: 20px;
                    height: 20px;
                    margin-right: 5px;
                    fill: #864293;
                }
                span{
                    font-size: 12px;
                    font-weight: 600;
                    color: #864293;
                }
            }
            .group-rows-wrapper{
                padding: 0 8px;
                display: flex;
                flex-direction: column;
                gap: 12px;
                margin-top: 16px;
                .group-row{
                    position: relative;
                    display: grid;
                    grid-template-columns: auto calc(100% - 188px) 100px 34px;
                    gap: 8px;
                    align-items: center;
                    &:last-child{
                        margin-top: 40px;
                    }
                    .remove-row-btn{
                        height: fit-content;
                        width: fit-content;
                        background-color: rgb(255, 237, 235);
                    }
                    .remove-row-btn svg{
                        font-size: 20px;
                    }
                    .row-id{
                        padding: 0 8px;
                        border: 1px solid #868FA0;
                        border-radius: 6px;
                        height: calc(100% - 2px);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .row-items-wrapper{
                        padding: 8px !important;
                        border: 2px dashed #D5DBE5;
                        border-radius: 10px;
                        &.error{
                            border-color: rgba(255, 0, 0, 0.25);
                            box-shadow: 0px 0px 12px rgba(255, 0, 0, 0.15);
                        }
                    }
                    .row-items{
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        min-height: 32px;
                        .row-name-field{
                            max-width: 140px;
                            .select-dropdown{
                                .MuiFormControl-root{
                                    width: 140px;
                                    .MuiSelect-select{
                                        font-size: 13px;
                                        .menu-item{
                                            width: 100%;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            min-width: unset;
                                        }
                                    }
                                }
                            }
                        }
                        > .select-dropdown{
                            .MuiFormControl-root{
                                width: 140px;
                                .MuiSelect-select{
                                    font-size: 13px;
                                }
                            }
                        }
                        .select-dropdown{
                            .MuiFormControl-root{
                                margin: 0;
                            }
                        }
                        .equal-symbol{
                            padding: 0 6px;
                            color: #868FA0;
                        }
                    }
                    .custom-checkbox {
                        label {
                            margin: 0 !important;
                            span{
                                &.MuiTypography-root{
                                    color: #464F60;
                                }
                            }
                        }
                    }
                    .text-input-wrapper{
                        gap: 0;
                    }
                }
            }
        }
    }
}

.group-type-field{
    .select-dropdown{
        width: 100%;
        .MuiFormControl-root{
            margin-bottom: 0;
            width: 100%;
        }
    }
}
.task-checkboxes{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 14px;
}
.menu-item{ 
    display: flex;
    width: 100%;
    justify-content: space-between;
    width: 130px;
    font-size: 13px;
    span{
        font-size: 18px;
        line-height: 1;
        &.text{
            font-size: 14px;
            display: inline-flex;
            align-items: center;
        }
    }
}
.formula-fields-wrapper{
    display: flex;
    align-items: center;
    gap: 10px;
    width: calc(100% - 330px);
    overflow-x: auto;
    overflow-y: hidden;
    &::-webkit-scrollbar{
        height: 4px;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #E9EDF5;
        border-radius: 2px;
    }
    .bg-green{
        font-size: 13px;
        text-transform: none;
        color: #20c374;
        background-color: #E1FCEF;
        height: 30px;
        display: inline-flex;
        align-items: center;
        border-radius: 4px;
        padding: 0 5px;
    }
    .select-dropdown{
        .MuiFormControl-root{
            &.shrink{
                width: 30px;
                overflow: hidden;
                min-width: unset;
                border-radius: 6px;
                svg{
                    display: none;
                }
                .MuiSelect-select{
                    padding-left: 9px;
                    .menu-item{
                        span{
                            position: absolute;
                            top: 0px;
                            left: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: #E1FCEF;
                            width: 30px;
                            height: 100%;
                            color: #20c374;
                            -webkit-text-fill-color: #20c374;
                        }
                    }
                }
            }
            .icon-dropdown{
                width: 152px;
                background-color: #E1FCEF;
                position: relative;
                fieldset{
                    border: unset;
                }
            }
        }
    }
    .text-input-wrapper{
        width: 146px;
        .MuiInputBase-root{
            padding-right: 10px;
        }
        .MuiInputAdornment-root{
            .MuiSvgIcon-root{
                font-size: 16px;
                cursor: pointer;
                color: #868FA0;
            }
        }
    }
    .variable-field{
        min-height: 20px ;
        border: 1px solid #dddddd;
        border-radius: 5px;
        font-size: 13px !important;
        overflow: hidden;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 6px 12px;
        min-width: 120px;
        color: #687182;
        text-transform: capitalize;
        &.parent{
            flex: 0 0 auto;
        }
        &.subtotal{
            min-width: 30px;
            display: flex;
            justify-content: center;
        }
        .formula-fields-wrapper{
            width: 100%;
            overflow-x: unset;
        }
    }
}
.menu-dropdown{
    .MuiMenu-paper{
        width: 240px;
        background-color: #F7F9FC;
        li{
            font-size: 14px;
        }
        .menu-head{
            background-color: #fff;
            padding-bottom: 12px;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
            > .MuiBox-root{
                display: flex;
                align-items: center;
                padding: 12px;
                padding-bottom: 0px;
                column-gap: 10px;
                button{
                    padding: 0 4px;
                    background-color: #E9EDF5;
                    height: 100%;
                    border-radius: 6px;
                    display: flex;
                    height: 26px;
                    svg{
                        font-size: 20px;
                        color: #864293;
                    }
                }
                .MuiTypography-root{
                    font-weight: 700;
                    font-size: 15px;
                    color: #687182;
                }
            }
            .text-input-wrapper{
                gap: 0;
                padding: 9px 12px;
                padding-bottom: 2px;
            }
            p{
                font-size: 15px;
                font-weight: 600;
                line-height: 1;
            }
        }
    }
}
.menu-item{
    &.row-names{
        min-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.no-item-text{
    padding: 12px;
    display: block;
    padding-top: 16px;
}