body {
  background-color: #f0eff0;
}

.main-wrapper {
  flex-grow: 1;
  max-width: calc(100% - 98px);
  margin: 0 24px 20px auto;
  height: calc(100vh - 76px);
  min-height: 560px;
}

.main-wrapper > div:first-of-type{
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 2px 0px #00000014;
  height: 100%;
  margin: 0;
  width: 100%;
}

.form-wrapper{
  height: 100%;
}

.page-table{
  height: 100%;
}

.error-txt {
  color: #d60e41;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.03em;
  font-family: inter, sans-serif;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
}

.d-flex {
  display: flex;
}

.flex-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pd-md {
  padding: 16px 24px;
}

.fw-600 {
  font-weight: 600 !important;
}

.form-title {
  padding: 24px 32px;
}

.form-title span {
  color: #A1A9B8;
}

.step-header {
  padding: 24px 24px 0;
}

.step-header span {
  color: #A1A9B8
}

.icon-btn {
  border: none;
  margin-left: 8px;
  background: none;
  cursor: pointer;
}

.divider {
  border-bottom: 1px solid #E9EDF5;
}

.flex-space-btw-wrapper {
  display: flex;
  justify-content: space-between;
}

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50% !important;
}

.pd-10 {
  padding: 10px 0px;
}

.pd-16 {
  padding: 16px 0px;
}

.pd-24 {
  padding: 24px;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.bg-white {
  background-color: white !important;
}

.t-center {
  text-align: center;
}

.t-uppercase {
  text-transform: uppercase;
}

.t-capitalize {
  text-transform: capitalize;
}

.fd-column {
  flex-direction: column;
}

.border-box {
  margin: 5px;
  outline-offset: 10px;
  outline: 2px dashed #bcc2ce;
  height: 84px;
  display: flex;
  align-items: center;
}
.border-box label > span{
  padding: 2px 16px;
  display: block;
  padding-top: 0;
  text-align: center;
}

.m-0 {
  margin: 0 !important;
}

.g-8 {
  gap: 8px
}

.modal-body {
  .MuiTextField-root .MuiInputBase-root {
    background: white;
  }
}

@media only screen and (max-width: 1279px) {
  .step-header{
    padding: 24px 16px 0;
  }
  .form-title {
    padding: 24px 16px;
  }
}

@media only screen and (max-width: 1023px) {
  .main-wrapper{
    max-width: calc(100% - 84px);
    margin: 0 14px 20px auto;
  }
  .form-title {
    padding: 18px 16px;
    padding-bottom: 24px;
  }
  .company-form-div{
    padding: 0 24px !important;
  }
} 