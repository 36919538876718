.login-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .login-wrapper {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 352px;
    padding: 40px;
    border-radius: 12px;
    .logo-image{
      margin-bottom: 32px;
      width: 116px;
    }
    .login-form{
      width: 100%;
      margin-top: 40px;
      &.otp{
        .otp-input-wrapper {
          div {
            gap: 14px;
            justify-content: center;
            input {
              height: 50px;
              width: 10% !important;
              border: 1px solid #e0e0e0;
              border-radius: 6px;
              &:hover {
                border-color: #86429366;
              }
              &:focus-visible {
                outline-color: #864293;
              }
            }
          }
        }
        .theme-button{
          margin-top: 24px;
        }
      }
      .text-input-wrapper{
        margin-bottom: 24px;
        &:last-of-type{
          margin-bottom: 0;
        }
        .MuiInputBase-root{
          .MuiInputAdornment-root{
            margin-right: 0;
          }
        }
      }
      .theme-button {
        width: 100%;
        margin-top: 40px;
      }
      .forgot-password-btn{
        margin-top: 6px;
        .theme-button{
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .logo-wrapper {
    max-height: 89px;
    margin-bottom: 32px;
  }

  .login-heading {
    text-align: center;

    .login-subtitle {
      padding-top: 10px;
    }
  }
}

.circle-input {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  font-size: 24px;
  margin: 5px;
  border: 1px solid #ccc;
}

.forgot-password-btn {
  .theme-button {
    margin-top: 0 !important;
  }
}

.password-strength-meter {
  width: 100%;
  text-align: left;
  margin-bottom: 24px;
}

.criteria-item {
  margin-bottom: 10px;
}

.criteria-item span {
  color: gray;
}

.criteria-item span.met {
  color: green;
}

.criteria-item span.not-met {
  color: #464F60;
}

.strength-meter {
  height: 5px;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin: 10px 0;
  overflow: hidden;
  position: relative;
}

.strength-bar {
  height: 100%;
  transition: width 0.5s;
}

.strength-bar.too-weak {
  background-color: #D60E41;
}

.strength-bar.could-be-stronger {
  background-color: #FF8751;
}

.strength-bar.almost-strong {
  background-color: #4662F5;
}

.strength-bar.now-stronger {
  background-color: #008649;
}
