.profile-page-container{
    width: 100%;
    margin: 0 auto !important;
    max-width: 1080px;
    height: fit-content !important;
    .profile-details{
        height: calc(100% - 160px);
        padding: 40px;
        .left-col{
            width: 260px;
            padding-right: 35px;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media only screen and (max-width: 1024px) {
                width: 28%;
            }
            .profile-image{
                width: 94px;
                height: 94px;
                position: relative;
                overflow: hidden;
                border-radius: 50%;
                border: 3px solid transparent;
                transition: 0.3s ease-in-out;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                .MuiAvatar-root{
                    width: 94px;
                    height: 94px;
                    font-size: 30px;
                }
                input{
                    position: absolute;
                    width: 94px;
                    height: 94px;
                    top: 0;
                    left: 0;
                    border-radius: 50%;
                    opacity: 0;
                    cursor: pointer;
                    z-index: 2;
                }
                span{
                    position: absolute;
                    bottom: 10px;
                    transition: 0.4s ease-in-out;
                    opacity: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: #EBF0FA;
                    border-radius: 6px;
                    width: 26px;
                    height: 26px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 1;
                    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
                    svg{
                        font-size: 18px;
                        color: #864293;

                    }
                }
                &::before{
                    background: rgba(255,255,255,0.4);
                    backdrop-filter: blur(5px);
                    position: absolute;
                    content: "";
                    bottom: -100%;
                    left: 0;
                    width: 94px;
                    height: 94px;
                    z-index: 1;
                    border-radius: 50%;
                    transition: 0.3s ease-in-out;
                }
                &:hover{
                    border-color: rgba(133, 66, 147, 0.45);
                    &::before{
                        bottom: -50%;
                    }
                    span{
                        opacity: 1;
                    }
                }
            }
            .details{
                text-align: center;
                .role-badge{
                    background: #EBF0FA;
                    color: #864293;
                    width: fit-content;
                    margin: 8px auto;
                    font-size: 14px;
                    padding: 2px 8px;
                    border-radius: 4px;
                    font-weight: 500;
                    margin-bottom: 14px;
                }
                span{
                    display: block;
                    margin-bottom: 8px;
                }
            }
        }
        .right-col{
            width: calc(100% - 330px);
            padding-left: 35px;
            @media only screen and (max-width: 1024px) {
                width: calc(100% - 28%);
            }
            .MuiTypography-headerh3{
                color: #687182;
                margin-bottom: 24px;
                display: block;
            }
            .input-wrapper{
                display: grid;
                grid-template-columns: repeat(2,1fr);
                gap: 32px;
            }
            .basic-info-wrapper{
                padding-bottom: 24px;
                margin-bottom: 24px;
                border-bottom: 1px solid #E9EDF5;
            }
            .general-settings-wrapper{
                .input-wrapper{
                    display: flex;
                    gap: 50px;
                    justify-content: space-between;
                    .user-roles{
                        .flex-container{
                            margin-bottom: 6px;
                        }
                    }
                    .left-input{
                        .select-dropdown{
                            min-width: 200px;
                            .MuiFormControl-root{
                                width: 100%;
                            }
                        }
                        .custom-tab{
                            margin-top: 16px;
                        }
                    }
                }
            }
        }
    }
    .company-frame{
        justify-content: flex-end;
        padding: 24px;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    }
}