@keyframes icon1 {
  from {
    top: 0;
    left: 0;
  }
  to {
    right: 0;
    bottom: 0;
    z-index: 1;
    transform: scale(1.05);
  }
}
@keyframes icon2 {
  from {
    right: 0;
    bottom: 0;
    z-index: 1;
    transform: scale(1.05);
  }
  to {
    top: 0;
    left: 0;
  }
}

.text-input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
  .MuiTextField-root {
    .MuiInputBase-input {
      padding: 6px 12px;
      font-size: 14px;
      @media only screen and (max-width: 1023px) {
        font-size: 13px;
      }
    }
    .Mui-disabled {
      cursor: not-allowed;
    }
    .MuiInputBase-root {
      border-radius: 6px;
      height: 32px;
      fieldset.MuiOutlinedInput-notchedOutline {
        border-color: #868fa029;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
      }
      &:hover {
        fieldset.MuiOutlinedInput-notchedOutline {
          border-color: #86429366;
        }
      }
      &.Mui-error {
          fieldset.MuiOutlinedInput-notchedOutline {
            border-color: #D60E41 !important; 
          }
      }
    }

    .MuiFormHelperText-root {
      color: #d60e41;
      margin-left: 0;
    }
  }
}

.custom-phone-input {
  .MuiTypography-root {
    margin-bottom: 6px;
  }
  .react-international-phone-input-container {
    .react-international-phone-country-selector {
      button {
        border-color: #868fa029;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        height: 32px;
      }
    }
    input {
      border-color: #868fa029;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      height: 32px;
      width: 100%;
    }
    &:hover {
      button,
      input {
        border-color: #86429366;
      }
    }
  }
}

.theme-button {
  .MuiButtonBase-root {
    padding: 6px 12px;
    border-radius: 6px;
    height: 32px;
    @media only screen and (max-width: 1279px) {
      font-size: 13px;
      padding: 4px 8px;
    }
  }
}

.transfer-btn {
  .theme-button {
    .MuiButtonBase-root {
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
      min-width: 64px;
      height: 48px;
      &:hover {
        background-color: #e3e3e3;
      }
    }
  }
}

.select-dropdown {
  .MuiInputBase-root {
    height: 32px;
    min-width: 103px;
    background-color: #ffffff;
    border-radius: 6px;
    .MuiSelect-select {
      font-size: 14px;
      .menu-item{
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: #868fa029;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
    }
    &:hover fieldset.MuiOutlinedInput-notchedOutline {
      border-color: #86429366;
    }
    &:focus-within fieldset.MuiOutlinedInput-notchedOutline {
      border-color: #86429366;
      box-shadow: 0 0 2px #86429366;
    }
  }
}

.custom-checkbox {
  .MuiTypography-root {
    font-size: 14px;
  }
}

.custom-tab {
  .MuiTypography-root{
    margin-bottom: 8px;
  }
  .tabs-container {
    background-color: #e9edf5;
    padding: 1px;
    border-radius: 6px;
    min-height: 30px !important;
    width: fit-content;
  }
  .MuiTabs-root .css-sv5aq8-MuiTabs-root {
    min-height: 0;
  }
  .MuiTab-root.Mui-selected {
    background-color: #F7F9FC !important;
    border-radius: 4px;
    box-shadow: 0px 2px 6px 0px #00000040;
    font-weight: 600;
    height: fit-content;
  }
  .MuiTab-root.MuiTab-textColorPrimary {
    font-weight: 500;
    min-height: 30px;
    padding: 4px 10px;
    @media only screen and (max-width: 1023px) {
      min-width: 67px;
      padding: 4px 6px;
      font-size: 12px;
    }
  }
  .MuiTabs-indicator {
    height: 0px;
  }
  .MuiTabs-flexContainer {
    align-items: center;
    padding: 2px;
  }
  .MuiButtonBase-root {
    text-transform: none;
  }
}

.custom-card {
  .MuiTypography-root {
    color: #1c1c1c;
  }
  .MuiPaper-root {
    box-shadow: none;
  }
  .MuiCardContent-root {
    background-color: #e3f5ff;
    border-radius: 16px;
    padding: 24px;
    width: 312px;
    height: 112px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    position: relative;
  }
  .card-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.modal-container {
  position: absolute;
  max-height: 80%;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #ffffff;
  box-shadow:
    0px 10px 30px 0px #00000033,
    0px 30px 70px 0px #1a224026,
    0px 0px 0px 1px #888faa1a;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  &.new-user-modal {
    @media only screen and (max-width: 1279px) {
      width: 55% !important;
    }
    @media only screen and (max-width: 1023px) {
      width: 70% !important;
    }
    .sticky-header{
      padding: 10px 16px;
    }
  }
  .modal-body {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    background: #f7f9fc;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #ccc;
    }
    .custom-autocomplete {
      .MuiAutocomplete-popper {
        height: 24%;
        .MuiPaper-root {
          max-height: 100%;
          .MuiAutocomplete-listbox {
            max-height: calc(100% - 16px);
          }
        }
      }
    }
  }
  .sticky-header{
    border-bottom: 1px solid #D5DBE5;
  }
  .sticky-footer{
    border-top: 1px solid #D5DBE5;
  }
}

.sticky-header,
.sticky-footer {
  position: sticky;
  background: #ffffff;
  z-index: 1;
}

.sticky-header {
  top: 0;
}

.sticky-footer {
  bottom: 0;
}

.custom-progress-bar {
  margin: auto;
  .MuiLinearProgress-root {
    height: 10px;
    border-radius: 25px;
  }
}

.custom-alert {
  .MuiAlert-root {
    border-radius: 8px;
    width: 100%;
  }
}

.progress-circle-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .custom-progress-circle {
    position: relative;
    display: inline-block;
    .default-progress-circle {
      position: absolute;
      color: #e3e3e3;
      top: 5px;
    }
    .MuiCircularProgress-circle {
      stroke-width: 2px;
    }
  }
}

.loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.search-wrapper {
  .search-filter-btn {
    width: fit-content;
    .MuiButtonBase-root {
      border-color: #868fa029;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      padding: 6px 14px !important;
      color: #464f60;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
      @media only screen and (max-width: 1023px) {
        padding: 6px 12px !important;
        padding-right: 8px !important;
        font-size: 13px !important;
      }
    }
    .text-input-wrapper {
      gap: 0;
      .MuiTextField-root {
        .MuiInputBase-root {
          width: 280px;
          @media only screen and (max-width: 1023px) {
            width: 180px;
          }
          fieldset {
            &.MuiOutlinedInput-notchedOutline {
              border-top-left-radius: 0px;
              border-bottom-left-radius: 0px;
            }
          }
        }
      }
    }
  }
  .search-chip-display {
    padding-top: 8px;
    .d-flex {
      align-items: center;
      p{
        color: #464f609a !important;
      }
    }
    .custom-chip{
      margin-bottom: 6px;
      display: inline-block;
    }
    .MuiChip-root {
      position: relative;
      
      &:hover {
        img {
          opacity: 1;
          width: 16px;
          margin-left: 5px;
          display: block;
        }
      }
      img {
        margin-right: 0px;
        opacity: 0;
        width: 0px;
        transition: all 0.1s ease-in-out;
        display: none;
      }
    }
  }
}
.not-found-container {
  min-height: 480px;
  height: calc(100vh - 160px);
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  padding: 30px;
  img{
    height: calc(100% - 170px);
    max-height: 360px;
  }
  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
}
.search-filter-head {
  padding: 8px 16px;
  padding-right: 10px;
}
#customized-menu {
  .MuiPopover-paper {
    width: 360px;
    overflow-y: hidden;
  }
  .accordion-list {
    max-height: 400px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #ccc;
    }
    .menu-list-item {
      margin: 0 !important;
      .MuiPaper-root {
        box-shadow: unset !important;
        background-color: #f7f9fc;
        border-bottom: 1px solid #e9edf5;
        .MuiAccordionSummary-root{
          width: 100%;
          gap: 16px;
          .MuiAccordionSummary-content{
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .MuiAccordionDetails-root {
          padding: 0 8px !important;
          .text-input-wrapper {
            gap: 0;
            margin-bottom: 4px;
            width: calc(100% - 76px);
          }
          .custom-checkbox {
            padding: 0 8px;
          }
        }
        .MuiTypography-root {
          font-weight: 500;
          color: #464f60;
        }
      }
    }
  }
  .menu-btn-wrapper {
    padding: 16px;
    width: calc(100% - 32px) !important;
  }
}

.image-upload-wrapper {
  display: flex;
  gap: 20px;
  flex-direction: column;

  .uploaded-image {
    height: 70px;
    width: 70px;
    border-radius: 50px;
  }
}

.upload-file-wrapper {
  padding: 5px;
  .image-upload-wrapper {
    margin: 16px 24px;
    .upload-file {
      border-radius: 6px;
      width: 100%;
      height: 242px;
      margin-top: 16px;
      .upload-file-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: center;
        align-items: center;
        .upload-file-img {
          position: relative;
          width: 76px;
          height: 76px;
          .icon_wrapper {
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            box-shadow: -2px 2px 10px 0px #00000026;
            position: absolute;
            &.chart {
              background-color: #eaf5ff;
              animation: icon1 1s 0.5s infinite;
            }
            &.file {
              background-color: #ffedec;
              animation: icon2 1s 0.5s infinite;
            }
            img {
              width: 32px;
            }
          }
        }
        .upload-file-btn {
          cursor: pointer;
          color: #864293 !important;
        }
      }
    }
  }
  .note-container {
    border-radius: 6px;
    margin: 10px;
    padding: 10px 15px;
    img{
      width: 100%;
    }
  }
}

.custom-datepicker {
  .MuiStack-root {
    overflow: unset;
  }
  .datepicker-input {
    .MuiInputBase-root {
      border-radius: 6px;
      height: 32px;
      input{
        font-size: 14px !important;
      }
      fieldset.MuiOutlinedInput-notchedOutline {
        border-color: #868fa029;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
      }
      &:hover {
        fieldset.MuiOutlinedInput-notchedOutline {
          border-color: #86429366;
        }
      }
    }
  }
  .MuiDialogActions-root,
  .MuiPickersToolbar-root {
    display: none;
  }
}

.custom-autocomplete {
  .text-input-wrapper{
    width: 100% !important;
  }
  .MuiInputBase-root {
    min-height: 32px;
    height: auto !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-left: 6px !important;
    .MuiChip-root{
      height: auto;
      padding: 1px 0;
      background-color: #E9EDF5;
      span{
        font-size: 12px;
        font-weight: 500;
        color: #5E5ADB;
      }
    }
    .MuiInputBase-input {
      display: none;
    }
  }
  .MuiAutocomplete-popper {
    .MuiPaper-root {
      .MuiAutocomplete-listbox {
        height: auto;
        max-height: 31vh;
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 3px;
          background-color: #ccc;
        }
      }
    }
  }
}

.creatable-autocomplete {
  .MuiInputBase-root {
    padding: 6px 12px !important;
    font-size: 14px;
    border-radius: 6px;
    height: 32px;
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: #868fa029;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
    }
    &:hover {
      fieldset.MuiOutlinedInput-notchedOutline {
        border-color: #86429366;
      }
    }
  }
}

.custom-chip {
  display: inline;
  margin-right: 6px;
  .MuiChip-root {
    border-radius: 4px;
    font-weight: 500;
    padding: 1px 8px;
    height: unset;
    .MuiChip-label {
      padding: 0;
      font-size: 12px;
      line-height: 18px;
    }
  }
}

.vertical-stepper-container {
  .MuiStepper-root {
    padding-top: 25px !important;
    margin: 0px 25px;
    @media only screen and (max-width: 1023px){
      margin: 0px 12px;
    }
    .MuiStep-root {
      padding: 6px 0;
      .MuiStepLabel-root {
        flex-direction: row-reverse;
        gap: 20px;
        .MuiStepLabel-iconContainer {
          .MuiSvgIcon-root {
            width: 32px;
            height: 32px;
            border: 2px solid #897d88;
            color: transparent;
            border-radius: 50%;
            text {
              fill: #897d88;
            }
            &.Mui-active {
              color: #897d88;
              border: unset;
              width: 36px;
              height: 36px;
              text {
                fill: #fff;
              }
            }
          }
        }
        .MuiStepLabel-label {
          display: flex;
          flex-direction: column;
          gap: 4px;
          text-align: right;
        }
      }
    }
    .MuiStepConnector-line {
      border-left: 0px;
      border-right: 1px solid #9e9e9e;
      min-height: 16px
    }
    .MuiStepConnector-vertical {
      margin-right: 27px;
    }
  }
  .stepper-wrapper {
    max-width: 290px;
    border-radius: 0px !important;
    text-align: center;
    position: relative;
    height: 100%;
    @media only screen and (max-width: 1279px){
      max-width: 240px;
    }
    @media only screen and (max-width: 1023px){
      max-width: 230px;
      position: absolute;
      top: 0;
      left: 24px;
      width: 100%;
      height: calc(100% - 24px);
      border-top-left-radius: 10px !important;
      border-bottom-left-radius: 10px !important; 
      overflow-y: auto;
      background-color: #fff;
      z-index: 3;
      &::-webkit-scrollbar{
        width: 4px;
      }
      &::-webkit-scrollbar-thumb{
        background-color: #ccc;
        border-radius: 2px;
      }
    }
  }
  .stepper-wrapper-narrow {
    max-width: 92px !important;
    @media only screen and (max-width: 1023px){
      left: 0;
    }
    .stepper-button{
      @media only screen and (max-width: 1023px){
        left: 48px;
      } 
    }
    .MuiStepConnector-root {
      margin: auto !important;
      @media only screen and (max-width: 1023px){
        margin-right: 18px !important;
      }
    }
    .MuiStepLabel-root {
      gap: 0px !important;
      .MuiStepLabel-iconContainer {
        padding-right: 0px;
      }
    }
  }
  .stepper-button {
    position: absolute;
    left: 30px;
    top: 30px;
    cursor: pointer;
    @media only screen and (max-width: 1023px){
      left: 20px;
    }
  }
}

.stepper-chip {
  .MuiChip-root {
    border-radius: 4px;
    font-weight: 500;
    padding: 1px 8px;
    height: unset;
    .MuiChip-label {
      padding: 0;
      font-size: 12px;
    }
  }
}

.model_wrapper{
  max-height: 100%;
  .table-wrapper{
    height: calc(100% - 52px);
  }
  .MuiTableContainer-root{
    height: calc(100% - 96px);
    width: 100%;
    overflow-x: auto;
    scrollbar-color: #dedadd transparent;
    @media only screen and (max-width: 1279px){
      height: calc(100% - 88px);
    }
    &::-webkit-scrollbar{
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 2px;
      background-color: #dedadd;
    }
    .MuiTable-root{
      height: 100%;
      .MuiTableBody-root{
        height: calc(100% - 200px);
        overflow-y: auto;

      }
      .MuiTableCell-root{
        &:first-child{
          min-width: 40px;
        }
      }
    }
  }
  .editable-table-wrapper{
    .table-header-wrapper{
      @media only screen and (max-width: 1279px) {
        padding: 8px 16px;
      }
    }
  }
  &.task_management{
    .editable-table-wrapper{
      .table-header-wrapper{
        @media only screen and (max-width: 1279px) {
          padding: 8px 16px;
        }
      }
    }
    .search-wrapper{
      .search-filter-btn{
        .text-input-wrapper{
          .MuiInputBase-root{
            @media only screen and (max-width: 1279px){
              width: 200px;
            }
          }
        }
      }
    }
    .MuiTableContainer-root{
      .MuiTable-root{
        .MuiTableCell-root{
          &:first-child{
            min-width: 0px;
          }
        }
      }
    }
  }
}
.upload-model{
  &.add-role{
    .custom-autocomplete {
      .MuiAutocomplete-popper {
        height: 31%;
        .MuiPaper-root {
          max-height: 100%;
        }
      }
    }
  }
}