.roles-table{
  height: calc(100% - 80px);
  overflow-y: auto;
  position: relative;
  @media only screen and (max-width: 1023px){
    height: calc(100% - 74px);
  }
}
.role-header {
  position: sticky;
  top: 0;
  background-color: #f7f9fc;
  color: #868fa0;
  padding: 8px;
  .MuiTypography-root {
    font-size: 12px;
    font-weight: 500;
  }
}
.role-table-wrapper {
  .role-container {
    border-bottom: 1px solid #e9edf5;
    padding: 8px;
    .text-input-wrapper {
      gap: 0;
    }
  }
  .editable-table-wrapper {
    .MuiTableContainer-root {
      .MuiTable-root {
        .MuiTableBody-root {
          background-color: #F7F9FC;
        }
      }
    }
  }
}
.upload-model-wrapper{
  width: 60%;
  max-width: 1000px;
}
.no-data-msg{
  margin-left: 32px !important;
  @media only screen and (max-width: 1279px){
    margin-left: 16px !important;
  }
}