.header {
  max-width: calc(100% - 84px);
  height: 56px;
  margin-left: auto;
  .header-container {
    height: 100%;
    align-items: center;
    display: flex;
    padding: 0px 24px;
    justify-content: space-between;
    @media only screen and (max-width: 1023px) {
      padding: 0 16px;
    } 
    .customer-select {
      .MuiInputBase-root {
        background-color: transparent !important;
      }
    }
    .d-flex {
      gap: 8px;
    }
    .profile-btn{
      .MuiAvatar-root, img{
        box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
        width: 40px;
        border-radius: 50%;
        height: 40px;
      }
    }
  }
}
.profile-popup{
  .MuiPaper-root{
    border-radius: 12px;
  }
  .profile-container {
    padding: 16px;
    padding-bottom: 8px;
    .profile-img{
      width: 50px;
    }
    .theme-button {
      width: 100%;
      .MuiButtonBase-root {
        justify-content: flex-start;
        height: unset !important;
        font-size: 14px;
        width: 100%;
        padding: 10px 14px;
        font-weight: 500;
        color: #464F60
      }
    }
    .user-details {
      gap: 16px;
      padding: 0px 0 16px;
    }
  }
}

.sidebar-wrapper {
  .drawer-container {
    padding: 9px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 52px;
    .MuiListItem-root {
      justify-content: center;
    }
    .app_logo{
      img{
        width: 100%;
      }
    }
    .menu-buttons {
      padding: 8px !important;
      border-radius: 6px !important;
      height: 40px;
      width: 40px;
      min-width: 0 !important;
      @media only screen and (max-width: 1023px) {
        height: 36px;
        width: 36px;
      }
      .image-filter {
        filter: brightness(0) invert(1);
      }
    }
  }
  .MuiDrawer-paper {
    background-color: #f0eff0;
    border-right: none;
  }
}
