.level-wrapper {
  gap: 16px;
  align-items: center;
  .level-card-container {
    border: 1px solid #dedadd;
    background-color: #ffffff;
    width: 80%;
    padding: 8px 16px;
    border-radius: 8px;
    justify-content: space-between;
    align-items: flex-end;
    .select-dropdown {
      min-width: 70%;
      max-width: 80%;
      .MuiFormControl-root{
        width: 100%;
      }
    }
    .theme-button {
      width: 42px;
    }
  }
}
.company_hierarchy_tab{
  .editable-table-wrapper{
    .table-header-wrapper{
      .headerButtons{
        min-width: 403px;
        @media only screen and (max-width: 1279px) {
          min-width: 350px;
        }
      }
    }
    .search-wrapper{
      .search-filter-btn{
        .text-input-wrapper{
          .MuiInputBase-root{
            @media only screen and (max-width: 1279px){
              width: 180px;
            }
          }
        }
      }
    }
  }
}