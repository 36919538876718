  .user-container {
    display: flex;
    padding: 0 32px 16px 32px;
    height: calc(100% - 164px);
    overflow-y: auto;
    &::-webkit-scrollbar{
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #dfdfdf;
        border-radius: 2px;
    }
    @media only screen and (max-width: 1023px) {
      flex-direction: column;
      padding: 0 24px 16px 24px;
    }
    .user-info-wrapper {
      width: 50%;
      border-right: 1px solid #e9edf5;
      padding-right: 40px;
      @media only screen and (max-width: 1279px) {
        padding-right: 20px;
      }
      @media only screen and (max-width: 1023px) {
        border-right: none;
        width: 100%;
        padding-right: 0;
      }
      .user-info {
        display: grid;
        gap: 24px;
        grid-template-columns: repeat(4, 1fr);
        padding-bottom: 32px;
        justify-content: space-between;
        grid-template-columns: repeat(2, 1fr) !important;
        &.user-modal {
          padding-top: 24px;
        }
        .text-input-wrapper{
          .textField-input{
            // max-width: 100%;
            width: 100%;
          }
          .MuiTextField-root {
             background: none;
             .MuiInputBase-root {
              background: white;
             }                                                    
          }
        }
      }
      .user-info-2 {
        padding: 32px 0;
        .user-roles {
          flex-direction: row;
          gap: 20px;
        }
      }
    }
    .user-permission-wrapper {
      padding-left: 40px;
      width: 50%;
      @media only screen and (max-width: 1279px) {
        padding-left: 20px;
      }
      @media only screen and (max-width: 1023px) {
        width: 100%;
        padding-left: 0;
      }
      .user-permissions {
        display: flex;
        gap: 24px;
        height: calc(100% - 24px);
        max-height: 450px;
        margin-top: 2px;
        .permission-list{
          display: grid;
          width: 100%;
          gap: 24px;
          grid-template-columns: repeat(2, 1fr);
          position: relative;
          .all-permissions {
            border: 1px solid #e9edf5;
            border-radius: 8px;
            width: calc(100% - 20px);
            height: calc(100% - 30px);
            padding: 8px;
            .MuiButtonBase-root{
              padding: 0 !important;
              margin-bottom: 8px;
            }
          }
          .transfer-btn{
            position: absolute;
            top: 39%;
            transform: translateX(-50%);
            left: 50%;
            z-index: 1;
          }
          .list-search{
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            svg{
              color: #A1A9B8;
            }
            .text-input-wrapper{
              .MuiInputBase-root{
                &::before{
                  border: unset;
                }
                input{
                  padding-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  .select-dropdown{
    .MuiFormControl-root{
      margin-left: 0 !important;
    }
  }
.users-list{
  .table-wrapper{
    .MuiTableCell-root{
      &:nth-of-type(2){
        color: #5E5ADB;
      }
    }
  }
}

.user-modal-loader {
  height: 400px;
  background-color: #f7f9fc;
}
.modal-body{
  .user-container{
    padding: 0 24px 16px 24px;
    .user-info-wrapper{
      padding-right: 0;
      border-right: 0;
    }
    .user-permission-wrapper {
      .user-permissions {
        height: 272px;
      }
    }
  } 
}