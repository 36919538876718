  .add-parameter-btn {
    height: 32px !important;
    margin-left: 10px !important;
    margin-top: 5px !important;
  }
  .group-wrapper {
    margin-top: 20px;
    border: 1px solid #c6c2c2;
    border-radius: 20px;
    position: relative;
    padding: 16px;
  }
  .group-inner-class{
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: -10px;
    left: 10px;
    background-color: #fff;
    padding: 0 8px;
    color: black;
    font-weight: 700;
  }
  
  .parameter-input-wrapper {
    display: flex;
    gap: 8px;
  }
  .add-parameter-btn-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .card-actions {
    display: flex;
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
    transition: 0.4s ease-in-out;
  }
  .card-cross-btn {
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    border-radius: 5px;
  }
  .cross-icon {
    text-align: center;
  }
  .parameter-card-container {
    padding-top: 0px !important;
    position: relative;
    flex-basis: fit-content !important;
    width: fit-content;
    max-width: 100% !important;
    min-width: 48%;
    .parameter-inner-wrapper{
      margin-right: 40px;
    }
  }
  .parameter-card-wrapper {
    padding: 16px 24px;
    min-height: 60px;
    display: flex;
    justify-content: center;
    box-shadow: none !important;
    background-color: #F7F9FC !important;
    border-radius: 20px !important;
    position: relative;

  }
  .parameter-daily-card-wrapper {
    padding: 16px 24px !important;
    min-height: 82px;
    box-shadow: none !important;
    background-color: #F7F9FC !important;
    border-radius: 20px !important;
    position: relative;
    padding-right: 60px !important;
    width: calc(100% - 84px);
  }
  .parameter-inner-wrapper {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow-x: auto;
    &::-webkit-scrollbar{
      height: 4px;
    }
    &::-webkit-scrollbar-thumb{
      background-color: #c6c2c2;
      border-radius: 2px;
    }
    .parameter-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        height: 100%;
        p{
          &:last-of-type {
            transition: 0.4s ease-in-out;
          }
        }
        .parameter-content {
          width: fit-content;
          p{
            &:last-of-type{
              color: #464F60 !important;
            }
          }
        }
        .parameter-value-wrapper{
          .parameter-value {
            font-weight: 700;
            font-size: 24px;
            text-align: end;
            @media only screen and (max-width: 1023px) {
                font-size: 18px !important;
            }
          }
        }
    }
  }
  .daily-parameter-inner-wrapper {
    display: flex;
    gap: 16px;
    width: 100%;
    .parameter-name{
      color: black;
    }
    .parameter-title{
      width: 70%;
      display: inline-block;
    }
  }
  .daily-parameter-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3px;
    width: 100%;
    .card-actions{
        position: absolute;
        transform: unset;
    }
  }
  .daily-parameter-card{
    display: grid;
    grid-template-columns: repeat(7, auto);
    gap: 2px 16px;
    width: 100%;
    overflow-x: auto;
    &::-webkit-scrollbar{
      height: 4px;
    }
    &::-webkit-scrollbar-thumb{
      background-color: #c6c2c2;
      border-radius: 2px;
    }
  .table-row{
    display: contents;
    .MuiTypography-root{
      width: fit-content;
    }
  }
    .table-cell{
      font-size: 10px;
      border: none;
    }
    .table-value{
      font-size: 10px;
      border: none;
      color: black;
      font-weight: 700;
    }
  }

.parameter-modal-container {
  padding: 8px 24px;
    .MuiTextField-root {
        background: none !important;
        .MuiInputBase-root {
        background: white;
        }                                                    
    }
    .select-dropdown {
      width: unset
    }
}
.model-step-wrapper{
  .budget-table-wrapper{
    .page-table{
      .table-wrapper{
        height: unset;
        padding: 10px;
        background-color: #F7F9FC;
        border-radius: 8px;
        table{
          border-top-right-radius: 8px;
          border-top-left-radius: 8px;
          overflow: hidden;
        }
        .MuiTableHead-root {
          .MuiTableCell-root{
            background-color: #E9EDF5;
            padding: 8px;
            line-height: unset;
            &:first-child{
              text-align: left;
              font-size: 12px;
              color: #687182;
              text-transform: uppercase;
            }
          }
        }
        .MuiTableBody-root{
          .MuiTableCell-root{
            line-height: unset;
          }
        }
      }
    }
  }
}