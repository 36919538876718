.company-frame {
    display: flex;
    gap: 48px;
    padding-bottom: 16px;
    @media only screen and (max-width: 1023px) {
        gap: 22px;
        flex-wrap: wrap;
    }
    .text-input-wrapper{
        width: 30%;
        &:last-of-type{
            width: fit-content;
        }
    }
    .custom-datepicker{
        max-width: 18%;
        width: fit-content;
        @media only screen and (max-width: 1023px) {
            max-width: 26%;
        }
    }
}

.custom-pd {
    padding-bottom: 48px !important;
}

.upload-image-container {
    border-top: 1px solid #E9EDF5;
    border-bottom: 1px solid #E9EDF5;
    padding: 24px 0px;

    .image-upload-wrapper {
        flex-direction: row !important;
        gap: 25px;
        .border-box{
            min-width: 300px;
            label{
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                cursor: pointer;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.company-form-div {
    display: flex;
    flex-direction: column;
    padding: 0 32px;
    gap: 24px;
    border-bottom: 1px solid #D5DBE5;
    height: calc(100% - 150px);
    overflow-y: auto;
    &::-webkit-scrollbar{
        width: 4px;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #dfdfdf;
        border-radius: 2px;
    }
}