.model-container {
  margin-top: 0 !important;
  position: relative;
  height: 100%;
  .model-wrapper {
    flex: 0 0 auto;
    padding-top: 0 !important;
    height: 100%;
    overflow-y: auto;
    padding-left: 10px !important;
    border-right: 2px solid #68718236 !important;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #dfdfdf;
      border-radius: 2px;
    }
    .vertical-stepper-container {
      height: 100%;
      .stepper-wrapper {
        padding-top: 24px;
        height: calc(100% - 24px);
      }
    }
  }
  .model-step-container {
    flex: 1;
    flex-grow: 1;
    padding: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 324px);
    height: 100%;
    max-width: unset !important;
    @media only screen and (max-width: 1023px) {
      padding-left: 64px !important;
    }
    .model-step-wrapper {
      height: calc(100% - 72px);
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 3px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #dfdfdf;
        border-radius: 2px;
      }
      .form-title {
        padding: 24px;
        .headerButtons{
          display: flex;
          align-items: center;
          gap: 12px;
        }
      }
    }
    .model-step-footer {
      flex: 1 0 auto;
      padding: 20px;
      box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.08);
      position: sticky;
      border-bottom-right-radius: 10px;
    }
  }
}
.upload-model {
  max-width: 520px;
  width: 50%;
}
.driver-table {
  height: calc(100% - 80px);
  overflow-y: auto;
}
#model-table {
  .select-dropdown {
    .MuiInputBase-root {
      min-width: unset !important;
      .MuiSelect-select {
        padding: 6px 12px;
        padding-right: 32px;
        text-align: left;
      }
    }
  }
}
.fine-tuning-step .table-wrapper {
  height: calc(100% - 185px);
}
.pb-120 {
  padding-bottom: 120px;
}
.custom-autocomplete {
  .MuiAutocomplete-option {
    font-size: 14px;
  }
}
.drag-handle {
  cursor: grab;
}

.dragging {
  opacity: 0.5;
  background-color: #e0e0e0;
}
.reorder-list{
    padding: 20px 24px;
    .smooth-dnd-container {
      position: relative;
    }
    .item{
        padding: 12px;
        background-color: #E9EDF5;
        margin-bottom: 12px;
        color: #464f60;
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .item svg{
        color: #A1A9B8;
    }
}